<template>
  <mobile-screen
    :header="true"
    screen-class="icon-app1 resources-screen gray-bg"
  >
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <home-button />
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "mega-containers",
              "mega-containers",
              "mega-containers"
            )
          }}
        </div>
        <template v-slot:right>
          <router-link
            v-if="can('MEGA_CONTAINERS', 'create')"
            :to="{
              name: 'r_add-mega-container',
              params: $route.params
            }"
          >
            <icon icon="#cx-hea1-add" />
          </router-link>
        </template>
      </top-header-menu-wrapper>
      <section class="search-wrapper time-zone-search">
        <div class="search-field">
          <input
            type="text"
            @keyup="searchQuery = $event.target.value"
            :placeholder="
              displayLabelName('mega-containers', 'mega-containers', 'search')
            "
          />
          <div class="icon search">
            <icon icon="#cx-app1-search-16x16" width="16" height="16" />
          </div>
        </div>
      </section>
      <div
        v-if="paginationData && paginationCountActive"
        class="clebex-pagination-count-wrapper"
      >
        <span class="clebex-pagination-count"
          >{{ topScreen }} - {{ bottomScreen }} /
          {{ paginationData.total }}</span
        >
      </div>
    </template>
    <div class="scroll"></div>
    <ul
      class="clebex-item-section pill"
      v-if="listOfMegaContainers && listOfMegaContainers.length"
    >
      <li
        class="clebex-item-section-item"
        :class="{ 'full-right-underline': !editing }"
        v-for="megaContainer in listOfMegaContainers"
        :key="megaContainer.id"
      >
        <button
          class="clebex-item-content-wrapper"
          @click="editMegaContainer(megaContainer)"
          v-if="!editing"
        >
          <span class="label">
            <span class="highlight">{{ megaContainer.name }}</span>
          </span>
          <span class="follow-up-icons">
            <span class="follow-up-icon-item">
              <icon icon="#cx-app1-information"></icon>
            </span>
          </span>
        </button>
        <div class="clebex-item-content-wrapper" v-else>
          <div class="checkbox tiny alt block">
            <input
              type="checkbox"
              :id="`resource-type${megaContainer.id}`"
              name="user"
              :selected="selectedMegaContainers.includes(megaContainer.id)"
              :value="megaContainer.id"
              @change="selectMegaContainer(megaContainer)"
              :disabled="!can('MEGA_CONTAINERS', 'delete')"
            />
            <label :for="`resource-type${megaContainer.id}`">
              <svg-icon icon="checkmark-strait"></svg-icon>
              {{ megaContainer.name }}</label
            >
          </div>
        </div>
      </li>
    </ul>
    <template v-slot:footer>
      <nav class="actions-menu theme-gray">
        <ul class="actions-list">
          <li class="action">
            <button
              class="action-btn"
              :class="{ active: editing }"
              @click="setEditing"
            >
              {{
                displayLabelName("mega-containers", "mega-containers", "select")
              }}
            </button>
          </li>
          <li class="action" v-if="selectedMegaContainers.length && editing">
            <button
              class="action-btn"
              :disabled="processing"
              @click="show = true"
            >
              <icon icon="#cx-app1-delete-02-14x14" />
            </button>
            <span class="action-btn deleted-count">{{
              "(" +
                selectedMegaContainers.length +
                "/" +
                listOfMegaContainers.length +
                ")"
            }}</span>
          </li>
        </ul>
      </nav>
    </template>
    <screen-modal
      v-if="show"
      class="confirm-modal"
      type="success"
      :confirm-action="startDelete"
      :confirm-button-label="displayLabelName('global.buttons.ok')"
      :cancelButtonLabel="displayLabelName('global.buttons.cancel')"
      :show="show"
      @close="show = false"
    >
      <h3 class="modal-title">
        {{ displayLabelName("containers", "containers", "delete-title") }}
      </h3>
      <p class="text">
        {{ displayLabelName("containers", "containers", "delete-action-text") }}
        <br />
        {{ displayLabelName("global.messages.delete-action-question") }}
      </p>
    </screen-modal>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import paginationMixin from "@/services/mixins/pagination/pagination-mixin";
export default {
  name: "MegaContainers",
  mixins: [paginationMixin],
  data() {
    return {
      processing: false,
      show: false,
      searchQuery: "",
      editing: false,
      selectedMegaContainers: []
    };
  },
  computed: {
    ...mapState("megaContainers", ["megaContainers", "selectedMegaContainer"]),
    listOfMegaContainers() {
      const query = this.searchQuery;
      if (this.megaContainers && this.megaContainers.length) {
        if (query && query.length > 1) {
          return this.megaContainers.filter(item =>
            item.name.toLowerCase().includes(query.toLowerCase())
          );
        } else {
          return this.megaContainers;
        }
      }
      return null;
    }
  },
  created() {
    this.setPaginationData(null);
    this.getMegaContainers();
  },
  methods: {
    ...mapActions("megaContainers", [
      "getMegaContainers",
      "setSelectedMegaContainer",
      "deleteMegaContainer"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    editMegaContainer(megaContainer) {
      if (this.can("MEGA_CONTAINERS", "update")) {
        this.setSelectedMegaContainer(megaContainer);
        setTimeout(() => {
          this.$router.push({
            name: "r_edit-mega-container",
            params: {
              mega_container_id: megaContainer.id
            }
          });
        }, 0);
      }
    },
    selectMegaContainer(megaContainer) {
      if (this.selectedMegaContainers.includes(megaContainer.id)) {
        this.selectedMegaContainers.splice(
          this.selectedMegaContainers.indexOf(megaContainer.id),
          1
        );
      } else {
        this.selectedMegaContainers.push(megaContainer.id);
      }
    },
    setEditing() {
      this.setSelectedMegaContainer(null);
      this.editing = !this.editing;
      this.$router.push({ name: "r_containers-mega" });
    },
    async startDelete() {
      this.processing = true;
      for (var i = 0; i < this.selectedMegaContainers.length; i++) {
        await this.deleteMegaContainer(this.selectedMegaContainers[i]);
      }

      this.processing = false;
      this.editing = false;
      this.selectedMegaContainers = [];
      this.setPaginationData(null);
      this.setSelectedMegaContainer(null);
      this.$nextTick(() => {
        // this.getMegaContainers();
        this.$router.push({ name: "r_containers-mega" });
      });
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  }
};
</script>
<style>
.deleted-count {
  position: relative;
  top: -4px;
  margin: 5px 8px;
  padding-left: 0px !important;
  margin-left: 0px;
}
</style>
